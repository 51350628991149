<template>
  <DefaultLayout>
    <Head title="AskGPT" />
    <div class="container-main">
      <ChatGptAI :video-url="videoUrl" />
      <!-- <Features /> -->
      <GetHelp />
      <!-- <Reviews /> -->
      <EmbraceFuture />
      <AllNeeds />
      <BaseFooter />
    </div>
  </DefaultLayout>
</template>

<script>
import { Head } from '@inertiajs/vue3';
import { trackEvent } from '@/Utils/analytics.js';

import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import BaseFooter from '@/Components/Global/BaseFooter.vue';
import Reviews from '@/Components/IndexLanding/Reviews.vue';
import GetHelp from '@/Components/IndexLanding/GetHelp.vue';
import AllNeeds from '@/Components/IndexLanding/AllNeeds.vue';
import EmbraceFuture from '@/Components/IndexLanding/EmbraceFuture.vue';
import ChatGptAI from '@/Components/Temporary/ChatGptAI.vue';
import userAttribution from '@/mixins/user-attribution.js';

export default {
  components: {
    ChatGptAI,
    Reviews,
    BaseFooter,
    AllNeeds,
    EmbraceFuture,
    GetHelp,
    DefaultLayout,
    Head,
  },
  mixins: [userAttribution],
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    trackEvent('start_page_shown');
  },
  unmounted() {
    localStorage.setItem('amplitudeId', amplitude.getDeviceId());
  },
};
</script>

<style lang="scss" scoped>
.container-main {
    @apply font-sans overflow-x-hidden z-10 text-black;
    min-height: calc(100dvh - 40px);
}
</style>
